import React, {useEffect} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location = '/'
    }
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
    </Layout>
  )
}

export default NotFoundPage
